span.editable-field {
	border-bottom: 1px dashed rgb(150, 150, 150);
}
div.click-to-edit p.form-control-static span:first-child {
	cursor: pointer;
}
div.click-to-edit p.form-control-static span.empty {
	color: rgb(182, 13, 13);
	font-style: italic;
}
header div.spinner {
	float: left;
	left: 220px;
	position: absolute;
	width: 100%;
}

div.editable-textarea div.ta-text.ta-editor {
	margin-bottom: 10px;
}

img.cat-icon {
    height: 25px;
}
div.modal-content img.icon {
    padding: 10px;
}
.form-group.required .control-label:after {
  content:"*";
  color:red;
}

div.popover.tablePopover {
    max-height: 635px !important;
}
div.popover.tablePopover .popover-content {
    max-height: 600px !important;
}

table.ngTable th.header,
table.ngTable th.filter {
    padding: 5px !important;
    color: #7D7D7D;
}
table.ngTable th.filter input.input-filter {
    height: 30px;
}

// tree css
.angular-ui-tree-handle {
    background: #f8faff;
    border: 1px solid #dae2ea;
    color: #7c9eb2;
    padding: 10px 10px;
}

.angular-ui-tree-handle:hover {
    color: #438eb9;
    background: #f4f6f7;
    border-color: #dce2e8;
}

.angular-ui-tree-placeholder {
    background: #f0f9ff;
    border: 2px dashed #bed2db;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.group-title {
    background-color: #687074 !important;
    color: #FFF !important;
}

div.popover-content.locations ul.categories {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    padding-left: 18px;
}

form.location div.categories {
    margin: 10px 0 10px 30px;

    span {
        margin-left: 15px;

        img {
            height: 32px;
            margin-right: 5px;
        }
    }

    button.actions {
        margin: 0 5px;
        float: right;
    }
}


div.modal-content form {

    .form-control-feedback {
        right: 15px;
        top: 11px;
    }
}

.modal-content .map {
    margin-top: 25px;

    .angular-google-map-container {
        height: 220px;
    }
}